import './home.css'; 
import { Header } from '../../containers'; 


export function Home() {
    return (
      <div className = "App">
        <div className = "gradient_bg">
          <Header /> 
        </div>
      </div>
    )
  }
  